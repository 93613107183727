import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17187650"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (value, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: index,
        class: "bar-section",
        style: _normalizeStyle({ height: `${((value as number) / _ctx.getMax) * 100}%` })
      }, null, 4)), [
        [
          _directive_tooltip,
          _ctx.formatValue((value as string)),
          void 0,
          { right: true }
        ]
      ])
    }), 128))
  ]))
}