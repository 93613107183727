
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex';
import StatCard from "@/components/StatCards/StatCard.vue";
import StatCardGl from "@/components/StatCards/StatCardGl.vue";

export default defineComponent({
  name: 'TopStatCards',
  components: {
    StatCard,
    StatCardGl,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      getPayableCustomDateRanges: 'accountingPayables/getCustomDateRanges',
      getReceivableCustomDateRanges: 'accountingReceivables/getCustomDateRanges',
      getPayableTabsLength: 'accountingPayables/getTabsLength',
      getReceivableTabsLength: 'accountingReceivables/getTabsLength',
      getPayableDefaultTabsLength: 'accountingPayables/getDefaultTabsLength',
      getReceivableDefaultTabsLength: 'accountingReceivables/getDefaultTabsLength',
    })
  },

  methods: {
    ...mapActions({
      setActiveTab: 'accountingForm/setActiveTab',
      setPayableActiveInnerTab: 'accountingPayables/setActiveTab',
      setReceivableActiveInnerTab: 'accountingReceivables/setActiveTab', 
      addPayableCustomDateRange: 'accountingPayables/addCustomDateRange',
      addReceivableCustomDateRange: 'accountingReceivables/addCustomDateRange',
      updatePayableCustomDateRange: 'accountingPayables/updateCustomDateRange',
      updateReceivableCustomDateRange: 'accountingReceivables/updateCustomDateRange',
    }),
    handleTabChange(e: any) {
      this.setActiveTab(e)
    },
    handleRowClick(data: any) {
      const customTabInfo = {
          label: '',
          range: ["", ""]
        } as any;
      const startDate = new Date();
      const endDate = new Date();
      let numberStart = null;
      let numberEnd = null;

      if (data) {
        customTabInfo.label = data.label;
        if (data.rangeStart && data.rangeEnd) {
          numberStart = data.rangeStart;
          numberEnd = data.rangeEnd;
        } else if (!data.rangeStart && data.rangeEnd) {
          numberEnd = data.rangeEnd;  
        } else if (data.rangeStart && !data.rangeEnd) {
          numberStart = data.rangeStart;
        }

        startDate.setDate(startDate.getDate() + numberStart);
        endDate.setDate(endDate.getDate() + numberEnd);
        customTabInfo.range = [numberStart != null ? startDate : undefined, numberEnd != null ? endDate : undefined];

        const tabType = data.type ?? '';
        const customDateRanges = tabType === 'Payables' ? this.getPayableCustomDateRanges : this.getReceivableCustomDateRanges;
        const updateCustomDateRangeFn = tabType === 'Payables' ? this.updatePayableCustomDateRange : this.updateReceivableCustomDateRange;
        const addCustomDateRangeFn = tabType === 'Payables' ? this.addPayableCustomDateRange : this.addReceivableCustomDateRange;
        const setInnerTabFn = tabType === 'Payables' ? this.setPayableActiveInnerTab : this.setReceivableActiveInnerTab;
        const getDefaultTabsLengthFn = tabType === 'Payables' ? this.getPayableDefaultTabsLength : this.getReceivableDefaultTabsLength;
        const getTabsLengthFn = tabType === 'Payables' ? this.getPayableTabsLength : this.getReceivableTabsLength;

        const index = customDateRanges.findIndex((range: any) => range.label.toLowerCase() === data.label.toLowerCase());

        if (index > -1) {
          updateCustomDateRangeFn(customTabInfo);
          setInnerTabFn(getDefaultTabsLengthFn + index);
        } else {
          addCustomDateRangeFn(customTabInfo);
          setInnerTabFn(getTabsLengthFn );
        }
      }
    }
  }

})
