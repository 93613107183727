
import { defineComponent } from 'vue'

import Card from "primevue/card";
import VerticalStat from './VerticalStat.vue';
import Receivables from "../UI/Receivables.vue";

export default defineComponent({
  name: 'StatCard',
  components: {
    Card, 
    Receivables,
    VerticalStat,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    statInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    handleRowClick(data: any) {
      this.$emit('row:click', { ...data, type: this.title})
    }
  }
})
