
import { defineComponent } from "vue";
import Tooltip from "primevue/tooltip";
import Utils from "@/utility/utils";

export default defineComponent({
  directives: {
    tooltip: Tooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [] as number[],
    },
    roundTotals: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    getMax() {
      return Math.max(...(this.data as number[]));
    },
  },
  methods: {
    formatValue(value: string) {
      const amount = Utils.formatAmount(value, false);
      return amount
    },
  },
});
