
import { defineComponent } from 'vue'
import Utils from "@/utility/utils";

import SingleBarStat from './SingleBarStat.vue';
import BarChart from "./BarChart.vue";

export default defineComponent({
  name: 'VerticalStat',
  components: {
    BarChart,
    SingleBarStat,
},
  props: {
    statInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isGlCard: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    roundTotals: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    formatValue(value: string) {
      return Utils.formatAmount(value, this.roundTotals);
    },
  },
})
