
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Calendar from "primevue/calendar";

import Utils from "@/utility/utils";


export default defineComponent({
  components: {
    Calendar,
    Column,
    ColumnGroup,
    DataTable,
    Dialog,
    Row,
  },
  emits: ["hide"],
  created() {
    this.hidden = this.show;
    this.dateRange = [new Date(this.periodStartDate), new Date(this.periodEndDate)];
  },
  computed: {},
  methods: {
    ...mapActions({
      getTransactions: "accountingInquiry/fetchGlTransactions",
    }),
    formatedAmount(amt: string) {
      if (!amt || parseInt(amt) === 0) {
        return "";
      } else {
        return Utils.formatPrice(amt);
      }
    },
    getGLData() {
      this.loading = true;
      this.getTransactions({
        startDate: this.dateRange[0].toLocaleDateString("en-US"),
        endDate: this.dateRange[1].toLocaleDateString("en-US"),
      })
        .then((response) => {
          this.tableData = response.gltrans_items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hideDialog() {
      this.hidden = true;
      this.tableData = [];
      this.$emit("hide", this.hidden);
    },
    getCreditAmount(amount: number) {
      return amount < 0 ? this.formatedAmount(""+Math.abs(amount)) : this.formatedAmount("0")
    },
    getDebitAmount(amount: number) {
      return amount >= 0 ? this.formatedAmount(""+amount) : this.formatedAmount("0")
    },
    getTotalDebitsBalance(data: any) {
      return data.reduce((acc: number, item: any) => {
        return acc + (+item.amounts >= 0 ? +item.amounts : 0);
      }, 0);
    },
    getTotalCreditsBalance(data: any) {
      return data.reduce((acc: number, item: any) => {
        return acc + (+item.amounts < 0 ? Math.abs(+item.amounts) : 0);
      }, 0);
    },
    handleHideCalendar(event: any) {
      if (!event[0] || event[1] === null) return;
      this.getGLData();
    },
  },
  data() {
    return {
      hidden: true,
      tableData: [],
      loading: false,
      dateRange: null as any,
      expandedRows: [] as any[],
    };
  },
  props: {
    show: Boolean,
    periodStartDate: {
      type: String,
      required: true
    },
    periodEndDate: {
      type: String,
      required: true
    },
  },
  watch: {
    show(newShow) {
      if (newShow) {
        this.getGLData();
      }
      this.hidden = newShow;
    },
    periodStartDate(newStartDate) {
      this.dateRange[0] = new Date(newStartDate);
    },
    periodEndDate(newEndDate) {
      this.dateRange[1] = new Date(newEndDate);
    },
  },
});
