
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TopStatCards from "@/components/StatCards/TopStatCards.vue";
import MessageBox from '@/components/MessageBox.vue';
import Footer from './Footer.vue';

import TabView from "primevue/tabview";
import TabPanel from 'primevue/tabpanel';
import Button from "primevue/button";

import { AccountingObject } from "@/types/state/accountingInquiry"
import Utils from "@/utility/utils";

const colors = ['#58b41c', '#04789f','#f5a623', '#fa332e', '#ca0c04'];

export default defineComponent({
  name: 'AccountsForm',
  components: {
    TabView,
    TabPanel,
    TopStatCards,
    Button,
    MessageBox,
    Footer,
  },
  data() {
    return {
      tabToDelete: {} as AccountingObject,
      showConfirmDeleteTab: false,
      activeLineItemTab: 0,
      active: 0,
      activeInnerTab: 0,
      accountItems: {
        payables: {
          label: "Payable",
          to: "/accounting/accounts-payable",
          statInfo: {
            horizontalStatsInfo: [],
            verticalStatsInfo: {
              daysQty: '0',
              daysType: 'DPO',
              amount: '0',
            }
          }
        },
        receivables: {
          label: "Receivable",
          to: "/accounting/accounts-receivable",
          statInfo: {
            horizontalStatsInfo: [],
            verticalStatsInfo: {
              daysQty: '0',
              daysType: 'DSO',
              amount: '0',
            }
          }
        },
        gl: {
          label: "GL",
          to: "/accounting/gl",
          statInfo: {
            verticalStatsInfo: {
              daysQty: '999',
              daysType: '',
              amount: '999',
              data: ['999', '999', '999', '999']
            },
            totals: [
              { label: 'REV', actual: '999', budget: '999'},
              { label: 'EXP', actual: '999', budget: '999'},
              { label: 'EBITDA', actual: '999', budget: '999'},
            ]
          }
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      getActiveTab: 'accountingForm/getActiveTab',
      getAPKpi: 'accountingPayables/getKpi',
      getARKpi: 'invoice/getKpi',
      getAccountingInquiryMap: 'accountingInquiry/getAccountingInquiryMap',
      getActiveMainTab: 'accountingInquiry/getActiveTab',
      getActiveLineItemsTab: 'accountingInquiry/getActiveLineItemsTab',
      getActiveInnerTab: 'accountingInquiry/getActiveInnerTab',
      getActiveInnerTabSuffix: 'accountingInquiry/getActiveInnerTabSuffix',
      innerTabs: 'accountingInquiry/getInnerTabs',
      getLineItemsMap: 'accountingInquiry/getLineItemsMap',
    }),
    isSaveButtonVisible(): boolean {
      const disabledInTabs = ['related-ap-items', 'register-history', 'attachments', 'line-item', 'gl-transaction']
      const pathElements = this.$route.fullPath.split('/').filter(element => element !== '')
      return (pathElements.length > 3 && !disabledInTabs.includes(pathElements[pathElements.length -1]) && !this.$route.fullPath.includes('line-item'))
    }
  },
  methods: {
    ...mapActions({
      setActiveTab: 'accountingForm/setActiveTab',
      fetchAccountsPayableKpi: 'accountingPayables/fetchAccountsPayableKpi',
      fetchAccountsReceivableKpi: 'invoice/fetchAccountsReceivableKpi',
      removeAccountingInquiryTab: 'accountingInquiry/removeOpenedAccountingInquiryTab',
      changeActiveMainTab: 'accountingInquiry/changeActiveTab',
      changeActiveInnerTab: 'accountingInquiry/changeActiveInnerTab',
      changeLineItemTab: 'accountingInquiry/changeLineItemTab',
      removeOpenedLineItem: 'accountingInquiry/removeOpenedLineItem',
    }),
    isInnerTabVisible(tab: any) {
      if (tab.accountType === 'accounts-payable') {
        return (tab.record.ap_id.includes('New') || (tab.record.transaction_id)) ? false : true
      }
      return true
    },
    getConfirmDeleteTabMessage(tab: any) {
      return `Unsaved Changes in ${tab.accountType === 'accounts-payable' ? 'Payable' : tab.accountType === 'accounts-receivable' ? 'Receivable' : 'GL'} #${tab.record && tab.record.ap_id ? tab.record.ap_id : (tab as any).record && (tab as any).record.id ? (tab as any).record.id : ''}`
    },
    areInnerTabsVisible(tab: any) {
      if (tab.accountType === 'gl') {
        return true
      } else if (tab.accountType === 'accounts-payable') {
        return this.getActiveLineItemsTab(tab.record.ap_id) === 0 && tab.accountType === 'accounts-payable' && !this.$route.fullPath.includes('line-item')
      }
    },
    handleTabChange(e: any) {
      this.active = e.index;
    },
    handleStatCardTabChange(e: any) {
      this.setActiveTab(e.index)
    },
    fetchAccountsPayable() {
      if (!this.getAPKpi) {
        this.fetchAccountsPayableKpi().then((res: any) => {
          this.setAccountsPayableKpiCardData(this.getAPKpi);
        });
      } else {
        this.setAccountsPayableKpiCardData(this.getAPKpi);
      }
    },
    setAccountsPayableKpiCardData(kpi: any) {
      this.accountItems.payables.statInfo.horizontalStatsInfo = kpi?.aging?.map((item: any, index: number) => {
        return {
          label: item.label,
          value: item.value,
          rangeStart: parseInt(item.rangeStart) || null,
          rangeEnd: parseInt(item.rangeEnd) || null,
          color: colors[index]
        }
      });
      this.accountItems.payables.statInfo.verticalStatsInfo.amount = kpi?.total;
      this.accountItems.payables.statInfo.verticalStatsInfo.daysQty = kpi?.dpo;
    },
    fetchAccountsReceivable() {
      if (!this.getARKpi) {
        this.fetchAccountsReceivableKpi().then((res: any) => {
          this.setAccountsReceivableKpiCardData(this.getARKpi);
        });
      } else {
        this.setAccountsReceivableKpiCardData(this.getARKpi);
      }
    },
    setAccountsReceivableKpiCardData(kpi: any) {
      this.accountItems.receivables.statInfo.horizontalStatsInfo = kpi?.aging.map((item: any, index: number) => {
        return {
          label: item.label,
          value: item.value,
          rangeStart: parseInt(item.rangeStart) || null,
          rangeEnd: parseInt(item.rangeEnd) || null,
          color: colors[index]
        }
      });
      this.accountItems.receivables.statInfo.verticalStatsInfo.amount = kpi?.total;
      this.accountItems.receivables.statInfo.verticalStatsInfo.daysQty = kpi?.dso;
    },
    handleCreatedOrUpdated(hookName?: string) {
      const activeAccountingTab = this.getActiveMainTab
      const accountingItems = this.getAccountingInquiryMap
      const accountType = accountingItems.length > 0 && accountingItems[activeAccountingTab - 1] ? accountingItems[activeAccountingTab - 1].accountType : ''
      const prop = accountType === 'accounts-payable' ? 'ap_id' : accountType === 'gl' ? 'transaction_id' : 'id'
      const accountId = accountingItems.length > 0 && accountingItems[activeAccountingTab - 1] ? accountingItems[activeAccountingTab - 1].record[prop] : '';

      if(accountType === 'accounts-payable') {
        this.activeLineItemTab = this.getActiveLineItemsTab(accountId) || 0;
        if (activeAccountingTab > 0 && accountingItems.length > 0 && this.activeLineItemTab > 0) {
          const itemId = this.getLineItemsMap(accountId)[this.activeLineItemTab - 1].li
          this.$router.push(`/accounting/accounts-payable/${accountId}/line-item/${itemId}`)
          
        } else if(hookName == 'created' && activeAccountingTab > 0 && accountingItems.length > 0 && this.activeLineItemTab === 0) {
          this.$router.push(`/accounting/accounts-payable/${accountId}`)
        }
        if(this.activeLineItemTab === 0 && this.getActiveInnerTab(accountId) > 0) {
          const suffix = this.getActiveInnerTabSuffix(accountId)
          this.$router.push(`/accounting/accounts-payable/${accountId}/${suffix}`)
        }

      } else if (accountType === 'accounts-receivable') {
        this.$router.push(`/accounting/accounts-receivable/${accountId}`)
      
      } else if (accountType === 'gl') {
        const suffix = this.getActiveInnerTabSuffix(accountId)
        this.$router.push(`/accounting/gl/${accountId}/${suffix}`)
      }
    },
    getTabTitle(tab: any) {
      const accountType = tab.accountType
      const prop = accountType === 'accounts-payable' ? 'ap_id' : accountType === 'gl' ? 'transaction_id' : 'id'
      let title = ''
      
      if(tab.record[prop]?.startsWith("New")) {
        return title += `New ${accountType === 'accounts-payable' ? 'Payable' : accountType === 'accounts-receivable' ? 'Receivable' : 'GL'}`
      }
      
      title += `${accountType === 'accounts-payable' ? 'Payable' : accountType === 'accounts-receivable' ? 'Receivable' : 'GL'}`
      return title
    },
    formatTabId(tab: any) {
      const accountType = tab.accountType
      const prop = accountType === 'accounts-payable' ? 'ap_id' : accountType === 'gl' ? 'transaction_id' : 'id'
      let id = tab.record[prop]
      return id?.includes('New') ? `#${id.split('-')[1]}` : `#${id}`
    },
    hasChanged(data: any) {
      const oldRecord = JSON.parse(JSON.stringify(data.old_record || {}))
      const record = JSON.parse(JSON.stringify(data.record))

      if ((record.ap_id && record.ap_id?.includes('New')) || (record.id && record.id?.includes('New')) || (record.transaction_id && record.transaction_id?.includes('New'))) {
        return true
      }

      const ignoreFields = ['isChanged', 'accountType', 'ap_id', 'id', 'transaction_id', 'lineItemsActiveTab', 'activeInnerTab', 'lineItemsMap']
      if (record.notes === undefined){
        ignoreFields.push('notes')
      }
      
      const result = Utils.compareTwoObjects(record, oldRecord, ignoreFields)
      return result
    },
    handleRemoveAccountingTab(tab: any) {
      if(this.hasChanged(tab)) {
        this.tabToDelete = {...tab}
        this.showConfirmDeleteTab = true;

      } else {
        this.removeAccountingInquiryTab({...tab})
      }
    },
    handleMainTabChange(e: any) {
      this.changeActiveMainTab(e.index !== undefined ? e.index : 0)
    },
    handleCloseSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
      this.removeAccountingInquiryTab({...this.tabToDelete})
      this.tabToDelete = {} as AccountingObject
    },
    handleCancelSaveFieldServiceTab() {
      this.showConfirmDeleteTab = false;
      this.tabToDelete = {} as AccountingObject
    },
    isReadOnly(tab: any) {
      const prop = tab.accountType === 'accounts-payable' ? 'ap_id' : tab.accountType === 'gl' ? 'transaction_id' : 'id'
      return !tab.record[prop].startsWith("New")
    },
    handleLineItemTabChange(event: any, tab: any) {
      this.changeLineItemTab({accountId: tab.record.ap_id, tabIndex: event.index})
      this.activeLineItemTab = this.getActiveLineItemsTab(tab.record.ap_id)
      if(this.activeLineItemTab === 0 && this.getActiveInnerTab(tab.record.ap_id) !== undefined && this.getActiveInnerTab(tab.record.ap_id) > 0) {
        const suffix = this.getActiveInnerTabSuffix(tab.record.ap_id)
        this.$router.push(`/accounting/accounts-payable/${tab.record.ap_id}/${suffix}`)
      } else if (this.activeLineItemTab === 0) {
        this.$router.push(`/accounting/accounts-payable/${tab.record.ap_id}`)
      } else if (this.activeLineItemTab > 0) {
        const itemId = this.getLineItemsMap(tab.record.ap_id)[this.activeLineItemTab - 1].li
        this.$router.push(`/accounting/accounts-payable/${tab.record.ap_id}/line-item/${itemId}`)
      }
    },
    handleInnerTabChange(event: any, tab: any) {
      this.changeActiveInnerTab({accountId: tab.record.ap_id ?? tab.record.transaction_id, tabIndex: event.index})
    },
    handleRemoveLineTab(item: any, accountId: string) {
      this.removeOpenedLineItem({accountId, lineItem: item})
      this.$router.push(`/accounting/accounts-payable/${accountId}`)
    }
  },
  created() {
    this.handleCreatedOrUpdated('created')

    this.fetchAccountsPayable();
    this.fetchAccountsReceivable();

  },
  updated() {
    this.handleCreatedOrUpdated()
  },

  watch: {
    '$route.path': {
      handler: function (val: any) {
        const index = Object.entries(this.accountItems).findIndex((item: any) => item.to === val);
        this.activeInnerTab = index;
      },
      immediate: true
    },
    '$route.params.payableId': {
      handler: function (val: any) {
        this.activeLineItemTab = this.getActiveLineItemsTab(val)
      },
      immediate: true
    },
    '$route.params.itemId': {
      handler: function (val: any) {
        this.activeLineItemTab = this.getActiveLineItemsTab(this.$route.params.payableId)
      },
      immediate: true
    },
    '$route.fullPath': {
      handler: function (val: any) {
        if (val.includes('accounting')) {
          this.handleCreatedOrUpdated()
        }
      },
      immediate: true
    }
  }
})
